import firebase from "firebase/app";
import React from "react";
import { Alert, Col, Container, Form, Image, Nav, Row } from "react-bootstrap";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { RouteComponentProps } from "react-router";
import SREventBus from "../../core/SREventBus";
import SRTagFilterBar from "../list/SRTagFilterBar";
import { ContentProps } from "../shcontext/context";
import { getFirebase } from "../shcontext/SRFirebase";
import SRBaseLayout from "../SRBaseLayout";


interface EditorProps extends RouteComponentProps<PathParams>, ContentProps {
    title: string;
}

interface PathParams {
    id: string;
}

export default class ContentEditor extends React.Component<EditorProps> {
    state = {
        id: '',
        isUserAdmin: false,
        title: '',
        value: '',
        summary: '',
        email: '',
        uid: '',
        tags: [],
        tags_master: [],
        image: '',
        errors: [],
        handleChange: (e: any) => {
            let {name, value} = e.target;
            this.setState({[name]: value});
        },
        fileSelected: (e: any) => {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                // convert image file to base64 string
                this.setState({image: reader.result});
            }, false);
            if (file) {
                reader.readAsDataURL(file);
            }
        },
        tagSelectionChanged: (selected: Set<string>) => {
            const new_tags: string[] = [];
            selected.forEach(v => new_tags.push(v));
            console.info(`Tags on selection - ${new_tags}`);
            this.setState({tags: new_tags});
        }
    }

    render() {
        return (
            <SRBaseLayout>
                <Container>
                    {
                        this.state.errors.map(e => {
                            return (
                                <Alert variant="danger">{e}</Alert>
                            )
                        })
                    }
                    <Row className="mb-3">
                        <Col>
                            <h4>{this.props.title}</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" placeholder="Title" name="title"
                                          value={this.state.title} onChange={this.state.handleChange}
                                          autoComplete="off"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-5">
                            <Form.Label>Summary</Form.Label>
                            <Form.Control type="textarea" placeholder="Summary" name="summary"
                                          value={this.state.summary} onChange={this.state.handleChange}
                                          autoComplete="off" as="textarea"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-5 col-md-6">
                            <Form.Label>Title Image</Form.Label>
                            <Form.Control type="file" placeholder="Image" name="image"
                                          onChange={this.state.fileSelected}
                            />
                        </Col>
                        <Col className="mt-5 col-md-6">
                            <Image src={this.state.image} height={100}></Image>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-5">
                            <Form.Label>Content</Form.Label>
                            <ReactQuill theme="snow"
                                        value={this.state.value}
                                        onChange={this.onContentChange}
                                        modules={{
                                            toolbar: {
                                                container: [
                                                    [{header: [1, 2, 3, 4, 5, 6]}, {font: []}],
                                                    [{size: []}],
                                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                    [{list: 'ordered'}, {list: 'bullet'}],
                                                    ['link', 'image'],
                                                    ['clean'],
                                                    ['code-block']
                                                ]
                                            }
                                        }}
                                        className="sr_quill mb-5"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SRTagFilterBar name={"stag_"+this.props.routePath}
                                            title="Tags"
                                            tags={this.state.tags_master}
                                            tags_selected={this.state.tags}
                                            onSelectionChange={this.state.tagSelectionChanged}
                                            horizontal={true}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <Nav>
                                <Nav.Link onClick={this.saveContent}>
                                    <i className="fas fa-save mr-2"></i>Save
                                </Nav.Link>
                                <Nav.Link href={"/staging/"+this.props.routePath}>
                                    <i className="fas fa-backspace mr-2"></i>Back
                                </Nav.Link>
                            </Nav>
                        </Col>
                        {
                            this.state.isUserAdmin ?
                                <Col md={2}>
                                    <Nav>
                                        <Nav.Link onClick={this.publishContent}>
                                            <i className="fas fa-globe mr-2"></i>Publish
                                        </Nav.Link>
                                    </Nav>
                                </Col>
                                : ''
                        }
                    </Row>
                    <Row>

                    </Row>
                </Container>
            </SRBaseLayout>
        );
    }

    onContentChange = (value: string) => {
        this.setState({value: value});
    }

    saveContent = async () => {
        const srFirebase = getFirebase(this.props);
        if (srFirebase) {
            try {
                const id = await srFirebase.getAPI(this.props.api)?.saveContentInStaging({
                    title: this.state.title,
                    content: this.state.value,
                    summary: this.state.summary,
                    author: this.state.email,
                    uid: this.state.uid,
                    created_at: firebase.firestore.Timestamp.now(),
                    tags: this.state.tags,
                    published: false,
                    image: this.state.image,
                }, this.props.match.params.id);
                if(id) {
                    this.props.history.push(`/staging/${this.props.routePath}/edit/${id}`);
                }
            } catch (e) {
                console.error(`Error saving - ${JSON.stringify(e)}`);
                if(e.code === 'permission-denied') {
                    this.setState({errors: ['Permission denied']});
                } else {
                    this.setState({errors: [e.message]});
                }
            }
        }
    }

    publishContent = async () => {
        const id = this.props.match.params.id;
        const srFirebase = getFirebase(this.props);
        if(srFirebase) {
            await srFirebase.getAPI(this.props.api)?.publishContent(id);
            this.props.history.push(`/staging/${this.props.routePath}`);
        }
    }

    async componentDidMount() {
        const srFirebase = getFirebase(this.props);
        const id = this.props.match.params.id;
        SREventBus.on('USER_SIGNED_IN', async () => {
            const userAdmin = await srFirebase.srCommon?.isUserAdmin();
            console.debug(`id - ${id}, User admin? - ${userAdmin}`);
            if(id && userAdmin) {
                this.setState({isUserAdmin: true});
            }
        });
        SREventBus.on('USER_SIGNED_OUT', () => {
                this.setState({isUserAdmin: false});
        });
        if (srFirebase) {
            let content_tags: firebase.firestore.DocumentData[] = [];
            let tags = await srFirebase.getAPI(this.props.api)?.fetchTags();
            if (tags) {
                tags.forEach(t => {
                    content_tags.push(t.data());
                })
            }
            if (id) {
                const content = await srFirebase.getAPI(this.props.api)?.fetchStagedContent(id);
                if (content && content.exists) {
                    const data = content.data();
                    let selectedTags: string[] = [];
                    data?.tags.forEach((t: string) => {
                        selectedTags.push(t);
                    });
                    if (data) {
                        this.setState({
                            id: id,
                            title: data.title,
                            value: data.content,
                            summary: data.summary,
                            email: data.author,
                            uid: data.uid,
                            image: data.image,
                            tags_master: content_tags,
                            tags: selectedTags,
                            errors: []
                        });
                        console.debug(`Tags - ${JSON.stringify(selectedTags)}`);
                    }
                }
            } else {
                const email = srFirebase.srCommon?.currentUser()?.email;
                const uid = srFirebase.srCommon?.currentUser()?.uid;
                this.setState({
                    email: email,
                    uid: uid,
                    tags_master: content_tags,
                })
            }
        }
    }
}