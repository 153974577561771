import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import SRBaseLayout from "./SRBaseLayout";


export default class SRFaq extends React.Component {

    render() {
        return (
            <SRBaseLayout>
                <Container>
                    <Row>
                        <Col md={10}>
                            <Card className="p-3">
                                <Card.Header as="h3"><b> FAQ </b> </Card.Header>
                                <Card.Body>
                                    <b>
                                        What TheSecureRadius.com do ? </b>
                                    <p>
                                        TheSecureRadius.com is helping Cyber Security professionals with the Cyber and Information
                                        Security news and happenings across the industry. The SecureRadius.com will be focussing to
                                        deliver newsletters and statistics in the future. It is our vision to help Cyber professionals
                                        to grow in their knowledge and help the Cyber Security space.
                                        <div>
                                            <img src="/Faq_1.png" alt="The Secure Radius" />
                                        </div>
                                    </p>

                                    <b>
                                        Why is Cyber Security ? </b>
                                    <p>
                                        Cyber security can be described as the collective methods by application of technologies, and
                                        processes to protect the confidentiality, integrity, and availability of computer systems, networks
                                        and data, against cyber-attacks or unauthorized access.
                                        It aims to reduce the risk of cyber attacks and protect against the unauthorised exploitation of
                                        systems, networks, and technologies.

                                        Cyber security looks like a straightforward concept, but that’s not the case and it is about
                                        safeguarding your data from hackers malicious activities and protecting our computer from various
                                        malware. Cyber threats are unpredictable and it comes from various corners and we as a company
                                        should be ready to protect us from all these threats.

                                        Human error is considered to be one of the highest contributing factors for various attacks and
                                        phishing is an outcome of a human error due to ignorance of Cyber Security alerts.
                                        The largest data breaches are happening because of human errors by the employees and it makes
                                        things very complicated. Only option to protect us and our company from Cyber attacks is
                                        through educating and continuous training of our employees.

                                        <p>
                                            It aims to reduce the risk of cyber attacks and protect against the unauthorised
                                            exploitation of systems, networks, and technologies.
                                        </p>

                                        <div>
                                            <img src="/Faq_Cyber_Security1.png" alt="What is Cyber Security" />
                                        </div>

                                    </p>

                                    <b>

                                        What is Information Security? </b>

                                    <p>
                                        To protect information by mitigating the risks surrounding the information risks. The ultimate aim is to
                                        prevent or reduce the probability of unauthorized or inappropriate access to data or disclosure of information.
                                        The information protection can be of any form either tangible or intangible and focus is protection of
                                        Confidentiality, Integrity and Availability of data.
                                        <div>
                                            <img src="/Faq_Information_Security1.png" alt="What is Cyber Security" />
                                        </div>

                                    </p>

                                    <b>
                                        How Cyber Security Operate or implemented?
                                    </b>

                                    <p>
                                        In general cyber security is significant for organization success, however; cyber threats are evolving in
                                        a rapid pace and the implementation of best practices and industry guidelines is a good way to protect us from
                                        Cyber Security threats and attacks. Improving employee awareness of good cyber hygiene on how to use company
                                        network and other IT systems.
                                        Inculcating good habits about things like strong password policies, avoiding connection to secure company systems
                                        via insecure personal devices, and mixing personal documents and emails with work-related ones.Secure network
                                        infrastructure is achieved through cyber security consultants risk assessment of your business systems and
                                        architectures.

                                        <div>
                                            <img src="/Faq_Operation_Impl.png" alt="Cyber Security Operation" />
                                        </div>

                                    </p>

                                    <b>
                                        How to train for Cyber Security?
                                    </b>

                                    <p>
                                        Best think to remember is practice, there is saying that Practice makes you perfect, so it is significant to
                                        use the knowledge and influence the team to apply the Cyber Security knowledge and that should be encouraged
                                        as a habit to prevent us from all problems. Like a scheduled fire drill, we should have a scheduled mock-up
                                        cyber attack (Whitehat-penetration testing)on the computer systems to see how employees are reacting to that.
                                        Also we should have regulat Cyber Security training for our employees on a regular basis to inculcate cyber
                                        security as a practice.

                                        <div>
                                            <img src="/Faq_Training.png" alt="Cyber Security Operation" />
                                        </div>

                                    </p>

                                    <b>
                                        What are the implications and costs of a Cyber Attack?
                                    </b>

                                    <p>
                                        The costs of attacks could be both tangible and intangible, thus it is difficult to calculate the intangible
                                        cost.
                                        When the reputation of a company is lost then it is very difficult to regain it. It costs less to prevent cyber
                                        attacks then the actual costs of breach or other attacks.
                                    </p>

                                    <p>
                                        As per the saying ‘Prevention is better than cure’.

                                        <div>
                                            <img src="/Faq_Implications.png" alt="Cyber Security Operation" />
                                        </div>

                                    </p>



                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </SRBaseLayout>
        );
    }

}