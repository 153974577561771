import React from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { RouteComponentProps } from "react-router";
import SREventBus from "../core/SREventBus";
import { getFirebase } from "./shcontext/SRFirebase";
import firebase from "firebase/app";

export default class SRNav extends React.Component<RouteComponentProps> {

    state = {
        signedInUser: null,
        isAdmin: false,
        getUser: (): firebase.User | null => {
            return this.state.signedInUser;
        }
    }

    render() {
        return (
            <Navbar bg="primary" variant="dark" expand="lg">
                <Navbar.Brand href="/">
                    <img src="/TheSecureRadius.svg" height="48" width="48" alt="The Secure Radius" />
                </Navbar.Brand>
                <Navbar.Brand href="/">
                    <span>TheSecureRadius</span>
                    <div><small>Cognizance</small></div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto fw-bolder text-white">
                        <Nav.Link href="/">HOME</Nav.Link>
                        <Nav.Link href="/news">NEWS</Nav.Link>
                        <Nav.Link href="/blog">BLOG</Nav.Link>
                        <Nav.Link href="/cyber/video">CYBER VIDEOS</Nav.Link>
                        <Nav.Link href="/cyberlaw/news">CYBER LAW</Nav.Link>
                        <Nav.Link href="/neighbourhood/news">NEIGHBOURHOOD ALERTS</Nav.Link>
                        <Nav.Link href="/malware/news">MALWARE</Nav.Link>

                    </Nav>
                    <Nav className="mr-auto fw-bolder text-white">
                        <Nav.Link href="/aboutus">ABOUT US</Nav.Link>
                        <Nav.Link href="/faq">FAQ</Nav.Link>
                        <Nav.Link href="/contactus">CONTACT US</Nav.Link>
                    </Nav>


                    {
                        this.state.signedInUser ?
                            <Nav className="justify-content-end mr-4">
                                <i className="fas fa-user-circle fa-2x mt-2"></i>
                                <NavDropdown title={this.state.getUser()?.email} id="account-nav-dropdown">
                                    <NavDropdown.Item href="/profile">MY PROFILE</NavDropdown.Item>
                                    <NavDropdown.Item href="/staging/blog">MY ARTICLES</NavDropdown.Item>
                                    <NavDropdown.Item href="/staging/news">MY NEWS ITEMS</NavDropdown.Item>
                                    <NavDropdown.Item href="/staging/nhalert">MY NEIGHBOURHOOD ALERTS</NavDropdown.Item>
                                    <NavDropdown.Item href="/staging/cyberlaw">MY CYBER LAW NEWS</NavDropdown.Item>
                                    <NavDropdown.Item href="/staging/malware">MY MALWARE NEWS</NavDropdown.Item>
                                    {
                                        this.state.isAdmin === true ?
                                            <NavDropdown.Item href="/admin/news/breaking">BREAKING NEWS
                                                ADMIN</NavDropdown.Item>
                                            : ''
                                    }

                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="#" onClick={this.signOut}>SIGN OUT</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                            :
                            <Nav className="justify-content-end mr-4">
                                <i className="fas fa-user-circle fa-2x mt-1"></i>
                                <Nav.Link href="/signin">SIGN IN</Nav.Link>
                            </Nav>
                    }
                </Navbar.Collapse>

            </Navbar>
        );
    }

    componentDidMount() {
        const firebase = getFirebase(this.props);
        SREventBus.on('USER_SIGNED_IN', (event: CustomEvent) => {
            const user = event.detail;
            console.info(`Nav - user signed in - ${user.email}`);
            this.setState({ signedInUser: user }, async () => {
                const admin = await firebase.srCommon?.isUserAdmin();
                console.info(`Is User Admin - ${admin}`);
                this.setState({ isAdmin: admin });
            });
        });

        SREventBus.on('USER_SIGNED_OUT', (event: CustomEvent) => {
            console.info(`Nav - user signed out`);
            this.setState({ signedInUser: null });
        });

        if (firebase && firebase.srCommon?.isAuthenticated()) {
            this.setState({ signedInUser: firebase.srCommon?.currentUser() });
        }
    }

    signOut = async () => {
        const firebase = getFirebase(this.props);
        if (firebase) {
            await firebase.doSignOut();
            this.props.history.push('/signin');
        }
    }
}