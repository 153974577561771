import React from "react";
import { Card, Col, Nav, Row } from "react-bootstrap";
import BaseProps from "../BaseProps";

interface DashboardRowProps extends BaseProps {
    itemSource: (props: any) => Promise<any[]>,
    badgeText: string,
    baseUrl: ''
}

export default class DashboardRow extends React.Component<DashboardRowProps> {

    state = {
        items: []
    }

    static defaultProps: DashboardRowProps = {
        itemSource: async (props: any): Promise<any[]> => [],
        context: new Map<string, any>(),
        badgeText: '',
        baseUrl: ''
    }

    render() {
        return (
            <Row style={{ paddingTop: "10px" }} className="pt-5 pb-2">
                <Col md={12}>
                    <Row className="pb-2">
                        <Col md={2}></Col>
                        <Col xs={12} md={8}>
                            <div className="dashboard_badge bg-primary">
                                {this.props.badgeText}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}></Col>
                        {
                            this.state.items.map((i: any) => {
                                return (
                                    <Col md={4} lg={2} xs={12} key={i.title}>
                                        <Card className="mt-2 ">
                                            {/*<Card.Title*/}
                                            {/*    className="ml-3 mt-2 font-weight-bold">*/}
                                            {/*    {*/}
                                            {/*        i.source_url*/}
                                            {/*            ? <Nav.Link href={i.source_url}*/}
                                            {/*                        target="_blank">{i.title?.substring(0, 80)}</Nav.Link>*/}
                                            {/*            : <Nav.Link*/}
                                            {/*                href={`${this.props.baseUrl}/${i.id}`}>{i.title?.substring(0, 80)}</Nav.Link>*/}
                                            {/*    }*/}
                                            {/*</Card.Title>*/}
                                            <Card.Img src={i.image} className="newsimage img-fluid"></Card.Img>
                                            <Card.Body className="newsitem-body">
                                                {/*<div
                                                    dangerouslySetInnerHTML={{__html: `${i.summary || i.content?.substring(0, 200)} ...`}}></div>*/}
                                                {
                                                    i.source_url
                                                        ? <Nav.Link href={i.source_url}
                                                            target="_blank">{i.title?.substring(0, 80)}</Nav.Link>
                                                        : <Nav.Link
                                                            href={`${this.props.baseUrl}/${i.id}`}>{i.title?.substring(0, 80)}</Nav.Link>
                                                }
                                            </Card.Body>
                                            {/*<Card.Footer className="small">*/}
                                            {/*    <span className="float-left">{i.author}</span>*/}
                                            {/*    <span className="float-right">*/}
                                            {/*        {new Date(i.created_at.seconds * 1000).toLocaleDateString()}*/}
                                            {/*    </span>*/}
                                            {/*</Card.Footer>*/}
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Col>

            </Row>
        );
    }

    async componentDidMount() {
        const news_items = await this.props.itemSource(this.props);
        this.setState({ items: news_items });
    }
}