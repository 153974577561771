import React from "react";
import SRBaseLayout from "../SRBaseLayout";
import {RouteComponentProps} from "react-router";
import {Col, Container, Image, Row} from "react-bootstrap";
import CommentList from "../list/CommentList";
import {withRouter} from "react-router-dom";
import {withContext} from "../shcontext";
import { ContentProps } from "../shcontext/context";

const Comments = withContext(withRouter(CommentList));

interface ItemViewerProps extends RouteComponentProps<{ id: string }>, ContentProps {
}

export default abstract class SRItemViewer extends React.Component<ItemViewerProps> {

    state = {
        title: '',
        content: '',
        author: '',
        created_at: new Date(),
        image: ''
    }

    render() {
        const {id} = this.props.match.params;
        return (
            <SRBaseLayout>
                <Container>
                    <h1 className="card-title mt-5 mb-5">{this.state.title}</h1>
                    {this.state.image ?
                        <Row>
                            <Col md={7} xs={12}>
                                <Image src={this.state.image} fluid={true} className="m-2"></Image>
                            </Col>
                        </Row>
                        : ""}

                    <div className="mt-5 mb-5" dangerouslySetInnerHTML={{__html: this.state.content}}></div>
                    <div className="justify-content-end mt-3">
                        {this.state.author}
                    </div>
                    <Comments entity={this.props.contentName} entityId={id}/>
                </Container>
            </SRBaseLayout>
        );
    }

    abstract getItemData(id: string): any;

    async componentDidMount() {
        const {id} = this.props.match.params;
        const itemData = await this.getItemData(id);
        this.setState({
            title: itemData.title,
            content: itemData.content,
            author: itemData.author,
            created_at: itemData.created_at,
            image: itemData.image
        });
    }
}