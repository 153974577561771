import React, { FormEvent } from "react";
import { Alert, Button, Card, Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import { RouteComponentProps } from "react-router";
import SREventBus from "../core/SREventBus";
import { getFirebase } from "./shcontext/SRFirebase";
import SRBaseLayout from "./SRBaseLayout";

export default class SRContactUs extends React.Component<RouteComponentProps> {

    validate() {
        const errors = [];
        if (!this.state.email || !this.state.email.match(/^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/)) {
            errors.push('Email is not valid')
        }
        if (!this.state.name || this.state.name.length == 0) {
            errors.push('Please provide your name')
        }
        if (!this.state.message || this.state.message.length == 0) {
            errors.push('Message cannot be empty')
        }
        this.setState({errors});
        if (errors.length > 0) {
            return false;
        }
        return true;
    }

    state = {
        email: '',
        name: '',
        message: '',
        handleChange: (e: any) => {
            let { name, value } = e.target;
            this.setState({ [name]: value });
        },
        errors: []
    }

    reset = () => {
        this.setState({ email: '', name: '', message: '', errors: [] })
    }

    submitRequest = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if (this.validate()) {
                const firebase = getFirebase(this.props);
                if (firebase) {
                    await firebase.saveContactUsMessage(this.state.email, this.state.name, this.state.message);
                    this.reset();
                    SREventBus.dispatch('SHOW_NOTIFICATION',
                        {
                            type: 'info', title: 'Message Received',
                            message: `Thank you. We have received your message.`
                        });
                }
            }
        } catch (err: any) {
            let message = 'Some error occurred. Please try again'
            if (err.message) {
                message = err.message;
            }
            this.setState({ errors: [message] });
        }
    }

    render() {
        return (
            <SRBaseLayout>
                <Container>
                    <Row>
                        <Col md={10}>
                            <Card className="p-3">
                                <Card.Header as="h3">Contact Us</Card.Header>
                                <Card.Body>
                                    <Form onSubmit={this.submitRequest}>
                                        <FormGroup>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="email" placeholder="Your email" name="email"
                                                value={this.state.email} onChange={this.state.handleChange}>
                                            </Form.Control>
                                        </FormGroup>
                                        <FormGroup>
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control type="text" placeholder="Your name" name="name"
                                                value={this.state.name} onChange={this.state.handleChange}>
                                            </Form.Control>
                                        </FormGroup>

                                        <FormGroup>
                                            <Form.Label>Message</Form.Label>
                                            <Form.Control as="textarea" placeholder="Your message" name="message" rows={5}
                                                value={this.state.message} onChange={this.state.handleChange}>
                                            </Form.Control>
                                        </FormGroup>

                                        <FormGroup>
                                            <Button type="submit">Submit</Button>
                                        </FormGroup>
                                    </Form>
                                </Card.Body>
                                {
                                    this.state.errors.length > 0
                                        ? <Card.Footer>
                                            {
                                                this.state.errors.map(e => {
                                                    return (
                                                        <Alert variant="danger">{e}</Alert>
                                                    )
                                                })
                                            }
                                        </Card.Footer>
                                        : ''
                                }
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </SRBaseLayout>
        );
    }

    componentDidMount() {
        this.setState({ errors: [] });
    }

}