import React from 'react';
import ReactDOM from 'react-dom';
import SRApp from './SRApp';
import reportWebVitals from './reportWebVitals';
import './App.scss'
import SRFirebase, {SRContext} from './components/shcontext'
import './index.css';
import './themes/bootstrap_yeti.css';
import PageProgress from "./components/PageProgress";

const context: Map<string, unknown> = new Map();

context.set('firebase', new SRFirebase());

ReactDOM.render(
    <PageProgress/>,
    document.getElementById('pageProgress')
);

ReactDOM.render(
  <React.StrictMode>
    <SRContext.Provider value={context}>
      <SRApp />
    </SRContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
