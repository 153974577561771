import firebase from "firebase";

export async function paginate(tags: string[],
                               collectionReference: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>,
                               start: number, size: number, query: firebase.firestore.Query<firebase.firestore.DocumentData>) {
    //find nth page's first document
    const lastVisible = await createQuery(tags, collectionReference)
        .limit((start - 1) * size)
        .get();
    if (lastVisible && lastVisible.docs.length > 0) {
        query = query.startAfter(lastVisible.docs[lastVisible.docs.length - 1]);
    }
    return query;
}

export function createQuery(tags: string[],
                            collectionReference: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>) {
    if (tags && tags.length > 0) {
        return collectionReference
            .where("tags", "array-contains-any", tags)
            .orderBy('created_at', "desc");
    } else {
        return collectionReference.orderBy('created_at', "desc");
    }
}