
const EVENT_PREFIX = 'SR_';

let event_registry = new Map<string, Set<CustomEventListener>>();

interface CustomEventListener {
    (e: any): void;
}

function addListener(eventName: string, listener: CustomEventListener): void {
    let listeners = event_registry.get(eventName);
    if(listeners === undefined) {
        listeners = new Set<CustomEventListener>();
        event_registry.set(eventName, listeners);
    }
    listeners.add(listener);
}

function unregister(eventName: string, listener: CustomEventListener): void {
    let listeners = event_registry.get(eventName);
    if(listeners) {
        listeners.delete(listener);
    }
}

function unregisterAll(eventName: string, callback: (listener: CustomEventListener) => void): void {
    let listeners = event_registry.get(eventName);
    if(listeners) {
        listeners.forEach(listener => {
            callback(listener);
        });
        event_registry.delete(eventName);
    }
}

const eventBus = {
    on(event: string, listener: CustomEventListener): void {
        const eventName = EVENT_PREFIX + event;
        document.addEventListener(eventName, listener);
        addListener(eventName, listener);
    },

    dispatch(event: string, data?: any): void {
        const customEvent = new CustomEvent(EVENT_PREFIX + event, {detail: data});
        document.dispatchEvent(customEvent);
    },

    unregister(event: string, listener: CustomEventListener): void {
        const eventName = EVENT_PREFIX + event;
        document.removeEventListener(eventName, listener);
        unregister(eventName, listener);
    },

    unregisterAll(event: string): void {
        const eventName = EVENT_PREFIX + event;
        unregisterAll(eventName, (listener => {
            document.removeEventListener(eventName, listener);
        }));
    },

    clear() {
        event_registry.forEach(async (val, key) => {
            for await (let lis of val) {
                document.removeEventListener(key, lis);
            }
        });
    }
}

export default eventBus;
