import React, {FormEvent} from "react";
import {Alert, Button, Card, Col, Container, Form, FormGroup, Row} from "react-bootstrap";
import SRBaseLayout from "./SRBaseLayout";
import {getFirebase} from "./shcontext/SRFirebase";
import SREventBus from "../core/SREventBus";
import {RouteComponentProps} from "react-router";


export default class SRSignIn extends React.Component<RouteComponentProps> {

    state = {
        email: '',
        password: '',
        handleChange: (e: any) => {
            let {name, value} = e.target;
            this.setState({[name]: value});
        },
        errors: []
    };

    render() {
        return (
            <SRBaseLayout>
                <Container>
                    <Row>
                        <Col md={6}>
                            <Card>
                                <Card.Title>Sign In</Card.Title>
                                <Card.Body>
                                    <Form onSubmit={this.signIn}>
                                        <FormGroup>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="email" placeholder="Your email" name="email"
                                                          value={this.state.email} onChange={this.state.handleChange}>
                                            </Form.Control>
                                        </FormGroup>
                                        <FormGroup>
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="password" placeholder="Password" name="password"
                                                          value={this.state.password}
                                                          onChange={this.state.handleChange}>
                                            </Form.Control>
                                        </FormGroup>
                                        <FormGroup>
                                            <Button type="submit">Sign In</Button>
                                        </FormGroup>
                                        <hr/>
                                        <FormGroup>
                                            Don't have an account yet - <Button href="/signup">Sign Up</Button>
                                        </FormGroup>
                                    </Form>
                                </Card.Body>
                                {
                                    this.state.errors.length > 0
                                        ? <Card.Footer>
                                            {
                                                this.state.errors.map(e => {
                                                    return (
                                                        <Alert variant="danger" key={e}>{e}</Alert>
                                                    )
                                                })
                                            }
                                        </Card.Footer>
                                        : ''
                                }

                            </Card>
                        </Col>
                    </Row>
                </Container>
            </SRBaseLayout>
        );
    }

    signIn = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const firebase = getFirebase(this.props);
            if (firebase) {
                await firebase.doPasswordAuthenticate(this.state.email, this.state.password);
                this.setState({errors: []});
                this.props.history.push('/');
            }
        } catch (err) {
            let message = 'Some error occurred. Please try again'
            if(err.code === 'auth/user-not-found' || err.code === 'auth/wrong-password') {
                message = "User not found or password doesn't match";
            }
            this.setState({errors: [message]});
        }
    }

    componentDidMount() {
        SREventBus.on('USER_SIGNED_IN', (event: CustomEvent) => {
            if(event.detail) {
                this.props.history.push('/');
            }
        });
    }

}