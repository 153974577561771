import firebase from "firebase/app";
import {showprogress} from "../../core/decorators";
import {createQuery} from "./util";
import SRCommon from "./SRCommon";
import SRContentAPI from "./SRContentAPI";

export default class SRNewsAPI implements SRContentAPI{
    constructor(private readonly srCommon: SRCommon,
                private readonly firestore: firebase.firestore.Firestore,
                private readonly functions: firebase.functions.Functions,
                private readonly auth: firebase.auth.Auth) {
    }
    deleteContent(id: string): Promise<void> {
        throw new Error("Method not implemented.");
    }

    @showprogress()
    async fetchContents(start: firebase.firestore.DocumentSnapshot<any> | null, size: number, tags: string[]) {
        let collectionReference = this.firestore.collection('sr_news');
        let query = createQuery(tags, collectionReference);
        if (start) {
            query = query.startAfter(start);
        }
        const dataSnapshot = await query.limit(size).get();
        return {
            data: dataSnapshot
        };
    }

    @showprogress()
    async fetchContent(id: string) {
        return this.firestore.collection('sr_news').doc(id).get();
    }

    @showprogress()
    async fetchTags() {
        const collectionReference = this.firestore.collection('sr_news_tag_master');
        return await collectionReference.get();
    }

    @showprogress()
    async fetchStagingContents(start: firebase.firestore.DocumentSnapshot<any> | null, size: number, tags: string[]) {
        let collectionReference = this.firestore.collection('sr_staging_news');
        let query = createQuery(tags, collectionReference);
        if (start) {
            query = query.startAfter(start);
        }

        const isAdmin = await this.srCommon.isUserAdmin();
        if(!isAdmin) {
            query = query.where('uid', '==', this.auth.currentUser?.uid)
        }

        const dataSnapshot = await query.limit(size).get();
        return {
            data: dataSnapshot
        };
    }

    @showprogress()
    async fetchStagedContent(id: string) {
        return this.firestore.collection('sr_staging_news').doc(id).get();
    }

    @showprogress()
    async saveContentInStaging(article: any, id: string | null): Promise<string> {
        const collectionReference = this.firestore.collection('sr_staging_news');
        if (id) {
            await collectionReference.doc(id).set(article);
            return id;
        } else {
            const documentReference = await collectionReference.add(article);
            return documentReference.id;
        }
    }

    //sr_breaking_news
    @showprogress()
    async saveBreakingNewsItems(newsItems: any[]) {
        if(newsItems) {
            const collectionReference = this.firestore.collection('sr_breaking_news');
            await collectionReference.doc('items').set({items: newsItems});
        }
    }

    @showprogress()
    async publishContent(id: string) {
        const sr_news_collection = this.firestore.collection('sr_news');
        const stagedNewsItem = await this.firestore.collection('sr_staging_news').doc(id).get();
        const sr_news = await sr_news_collection
            .where('sr_source_id', '==', id)
            .get();
        if(sr_news.size > 0) {
            //update
            const sr_news_doc = sr_news.docs[0].data();
            sr_news_doc.title = stagedNewsItem?.data()?.title;
            sr_news_doc.content = stagedNewsItem?.data()?.content;
            sr_news_doc.summary = stagedNewsItem?.data()?.summary;
            sr_news_doc.image = stagedNewsItem?.data()?.image ? stagedNewsItem?.data()?.image : '';
            sr_news_doc.created_at = firebase.firestore.Timestamp.now();
            sr_news_doc.author = stagedNewsItem?.data()?.author;
            sr_news_doc.tags = stagedNewsItem?.data()?.tags;

            sr_news_collection.doc(sr_news.docs[0].id).set(sr_news_doc);
        } else {
            //add
            sr_news_collection.add({
                title: stagedNewsItem?.data()?.title,
                content: stagedNewsItem?.data()?.content,
                summary: stagedNewsItem?.data()?.summary,
                image: stagedNewsItem?.data()?.image ? stagedNewsItem?.data()?.image : '',
                created_at: firebase.firestore.Timestamp.now(),
                author: stagedNewsItem?.data()?.author,
                sr_source_id: id,
                tags: stagedNewsItem?.data()?.tags
            });
        }

        const data = Object.assign(stagedNewsItem.data(), {published: true});
        await this.firestore.collection('sr_staging_news')
            .doc(id).set(data);
    }

}