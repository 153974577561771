import firebase from "firebase";
import { Nav, Navbar, Table, ToggleButton } from "react-bootstrap";
import AbstractListPage from "../list/AbstractListPage";
import { getFirebase } from "../shcontext/SRFirebase";

export default class BreakingNewsSelectionList extends AbstractListPage {

    async refreshTags() {
        const srFirebase = getFirebase(this.props);
        if (srFirebase) {
            let newsTags: firebase.firestore.DocumentData[] = [];
            let tags = await srFirebase.srNewsAPI?.fetchTags();
            if (tags) {
                tags.forEach(t => {
                    newsTags.push(t.data());
                })
                this.setState({tags: newsTags});
            }
        }
    }

    async refreshItems() {
        const srFirebase = getFirebase(this.props);
        if (srFirebase) {
            let news_items: firebase.firestore.DocumentData[] = [];
            let tags: string[] = [];
            this.state.selectedTags.forEach(v => tags.push(v));

            let news = await srFirebase.srNewsAPI?.fetchContents(this.state.last_doc_snapshot, this.state.page_size, tags);
            if (news) {
                news.data.forEach(doc => {
                    const item = doc.data();
                    item.id = doc.id;
                    news_items.push(item);
                });
                if (this.state.items.length > 0 && this.state.last_doc_snapshot) {
                    const existingItems: any[] = this.state.items;
                    existingItems.push(...news_items);
                    news_items = existingItems;
                }
                this.setState({
                    items: news_items,
                    last_doc_snapshot: news.data.docs[news.data.size - 1]
                });
            }
        }
    }

    protected renderListWrapper(): JSX.Element {
        return <Table className="w-80">
            <thead>
            <tr>
                <th></th>
                <th>Title</th>
                <th>Author</th>
            </tr>
            </thead>
            <tbody>
            {
                this.state.items.map(i => {
                    return this.renderListItem(i);
                })
            }
            </tbody>
        </Table>;
    }

    protected renderListItem(i: any): JSX.Element {
        return (
            <tr key={i.title}>
                <td>
                    <ToggleButton
                        type="checkbox"
                        variant="secondary"
                        value="1"
                        checked={this.isSelected(i)}
                        onChange={(e) => this.setChecked(i)}
                    >

                    </ToggleButton>
                </td>
                <td>{i.title}</td>
                <td>{i.author}</td>
            </tr>
        );
    }

    setChecked = (checked: any) => {
        let extendedState = this.state.extension;
        let selectedItems = extendedState.get('selected_items');
        if(!selectedItems) {
            selectedItems = [];
            extendedState.set('selected_items', selectedItems);
        }
        if(selectedItems.includes(checked)) {
            delete selectedItems[selectedItems.indexOf(checked)];
        } else {
            selectedItems.push(checked);
        }
        this.setState({extension: extendedState});
    }

    saveBreakingNews = () => {
        const srFirebase = getFirebase(this.props);
        if (srFirebase) {
            const selectedItems = this.getSelectedItems();
            if(selectedItems) {
                srFirebase.srNewsAPI?.saveBreakingNewsItems(selectedItems);
            }
        }
    }

    protected renderActionBar(): JSX.Element {
        return (
            <Navbar variant="light" className="justify-content-end mr-5">
                <Nav>
                    <Nav.Item onClick={this.saveBreakingNews} className="btn">
                        <i className="fas fa-save mr-2"></i>Save
                    </Nav.Item>
                </Nav>
            </Navbar>
        )
    }

    isSelected = (i: any): boolean => {
        let selectedItems = this.getSelectedItems();
        if(selectedItems) {
            return selectedItems.includes(i);
        }
        return false;
    }

    private getSelectedItems() {
        let extendedState = this.state.extension;
        let selectedItems = extendedState.get('selected_items');
        return selectedItems;
    }
}
