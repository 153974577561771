import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import SRNav from "./SRNav";
import {withRouter} from "react-router-dom";
import {withContext} from "./shcontext";
import SRFooter from "./SRFooter";
import CookieConsent from "react-cookie-consent";
import Notification from "./Notification";
import SREventBus from "../core/SREventBus";

const Nav = withContext(withRouter(SRNav));

export default class SRBaseLayout extends React.Component {
    render() {
        return (
            <div>
                <Nav></Nav>
                <Container className="mt-2 mb-2" fluid>
                    <Row>
                        <Col className="col-md-4"/>
                        <Col>
                            <Notification></Notification>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {this.props.children}
                        </Col>
                    </Row>
                </Container>
                <CookieConsent
                    location="bottom"
                    buttonText="I Accept"
                    cookieName="thesecureradius_cookie_consent"
                    style={{ background: "#2B373B" }}
                    buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                    expires={150}
                >
                    We use cookies to collect information about how you use TheSecureRadius.com.
                    We use this information to make the website work as well as possible and improve our service.
                </CookieConsent>
                <SRFooter/>
            </div>
        );
    }

    componentDidMount() {
        const item = localStorage.getItem('notification');
        if(item) {
            SREventBus.dispatch('SHOW_NOTIFICATION', JSON.parse(item));
            localStorage.removeItem('notification');
        }
    }

}