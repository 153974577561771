import firebase from "firebase";
import { Button, Nav, Navbar, Table } from "react-bootstrap";
import AbstractListPage from "../list/AbstractListPage";
import { getFirebase } from "../shcontext/SRFirebase";

export default class ContentStagingList extends AbstractListPage {
    async refreshTags() {
        const srFirebase = getFirebase(this.props);
        if (srFirebase) {
            let content_tags: firebase.firestore.DocumentData[] = [];
            let tags = await srFirebase.getAPI(this.props.api)?.fetchTags();
            if (tags) {
                tags.forEach(t => {
                    content_tags.push(t.data());
                })
                this.setState({ tags: content_tags });
            }
        }
    }

    async refreshItems() {
        const srFirebase = getFirebase(this.props);
        if (srFirebase) {
            let items: firebase.firestore.DocumentData[] = [];
            let tags: string[] = [];
            this.state.selectedTags.forEach(v => tags.push(v));

            let contentItems = await srFirebase.getAPI(this.props.api)?.fetchStagingContents(this.state.last_doc_snapshot, this.state.page_size, tags);
            if (contentItems) {
                contentItems.data.forEach(doc => {
                    const item = doc.data();
                    item.id = doc.id;
                    items.push(item);
                });
                if (this.state.items.length > 0 && this.state.last_doc_snapshot) {
                    const existingItems: any[] = this.state.items;
                    existingItems.push(...items);
                    items = existingItems;
                }
                this.setState({
                    items: items,
                    last_doc_snapshot: contentItems.data.docs[contentItems.data.size - 1]
                });
            }
        }
    }

    protected renderListWrapper(): JSX.Element {
        return <Table className="w-80">
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Author</th>
                    <th>Status</th>
                    <th>Updated at</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {
                    this.state.items.map(i => {
                        return this.renderListItem(i);
                    })
                }
            </tbody>
        </Table>;
    }

    protected renderListItem(i: any): JSX.Element {
        return (
            <tr key={i.title}>
                <td><Button variant="link" href={"/staging/" + this.props.routePath + "/edit/" + i.id}>{i.title}</Button></td>
                <td>{i.author}</td>
                <td>
                    {
                        i.published ? <i className="fas fa-globe"></i>
                            : <i className="fab fa-firstdraft"></i>
                    }
                </td>
                <td>{new Date(i.created_at.seconds * 1000).toLocaleDateString()}</td>
                <td>
                    <Button variant="link" onClick={() => this.confirmDelete(i.id)}>
                        <i className="fas fa-trash" />
                    </Button>
                </td>
            </tr>
        );
    }

    protected renderActionBar(): JSX.Element {
        return (
            <Navbar variant="light" className="justify-content-end mr-5">
                <Nav>
                    <Nav.Link href={"/staging/" + this.props.routePath + "/new"}>
                        <i className="fas fa-plus-circle mr-2"></i>Add
                    </Nav.Link>
                </Nav>
            </Navbar>
        )
    }

    confirmDelete = async (id: string) => {
        const srFirebase = getFirebase(this.props);
        this.confirm(`Are you sure to delete this ${this.props.contentName}?`, async () => {
            if (srFirebase) {
                await srFirebase.getAPI(this.props.api)?.deleteContent(id);
                this.reload();
            }
        }, () => {
        });

    }
}
