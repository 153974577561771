import SREventBus from "../../core/SREventBus";
import app from "firebase";
import firebase from "firebase";

export default class SRCommon {

    constructor(
        private readonly firestore: firebase.firestore.Firestore,
        private readonly functions: firebase.functions.Functions,
        private readonly auth: firebase.auth.Auth) {
    }

    authStateChanged(user: any) {
        if (user) {
            //signed in
            // console.info(`Signed in user - ${JSON.stringify(user)}`);
            SREventBus.dispatch('USER_SIGNED_IN', user);
        } else {
            //signed out
            // console.info(`User signed out`);
            SREventBus.dispatch('USER_SIGNED_OUT', null);
        }
    }

    isAuthenticated(): boolean {
        const currentUser = app.auth().currentUser;
        return !!currentUser && !!currentUser.uid
    }

    currentUser(): firebase.User | null {
        return app.auth().currentUser;
    }

    async isUserAdmin(): Promise<boolean> {
        const role = await app.firestore().collection(`sr_roles`)
            .doc(`${app.auth().currentUser?.uid}`).get();
        const roleName = role?.data()?.role.trim();
        console.debug(`Is Admin ${app.auth().currentUser?.uid} - ${roleName === 'Admin'}`);
        return roleName === 'Admin';
    }
}
