import React from "react";
import {Button, Modal} from "react-bootstrap";

export default class ConfirmDialog extends React.Component {

    state = {
        showDialog: false,
        text: '',
        okFunction: () => {},
        cancelFunction: () => {}
    }

    render() {
        return <div>
            <Modal show={this.state.showDialog} backdrop="static" keyboard={false} centered>
                <Modal.Body>
                    {this.state.text}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.okClicked}>OK</Button>
                    <Button variant="secondary" onClick={this.cancelClicked}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </div>;
    }

    okClicked = () => {
        try {
            this.state.okFunction();
        } finally {
            this.clearState();
        }
    }

    cancelClicked = () => {
        try {
            this.state.cancelFunction();
        } finally {
            this.clearState();
        }
    }

    clearState = () => {
        this.setState({
            showDialog: false,
            text: '',
            okFunction: () => {},
            cancelFunction: () => {}
        });
    }

    confirm(text: string, okFn: () => void, cancelFn: () => void) {
        this.setState({
            showDialog: true,
            text: text,
            okFunction: okFn,
            cancelFunction: cancelFn
        });
    }

    componentDidMount() {

    }
}