import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import SRBaseLayout from "./SRBaseLayout";


export default class SRAbout extends React.Component {

    render() {
        return (
            <SRBaseLayout>
                <Container>
                    <Row>
                        <Col md={10}>
                            <Card className="p-3">
                                <Card.Header as="h3"><b> About Us </b> </Card.Header>
                                <Card.Body>
                                    <b>
                                        TheSecureRadius.com </b>
                                    <p>

                                        The Secure Radius is striving to provide the Significant Information Security and Cyber information to
                                        experts and enthusiasts to promote awareness, knowledge and induce interest for entrants in cyber
                                        security news. The ultimate aim is to reach more cyber security professional and spread the
                                        knowledge across the industry experts. The Secure Radius has been operating since June 2021 and
                                        we are constantly working hard to provide compelling feature online to ensure users are benefitted.

                                        <div>
                                            <img src="/Abouts_img1.png" alt="Cyber Security information" />
                                        </div>

                                    </p>

                                    <p>
                                        The secure radius has got various sections like:
                                    </p>
                                    <p>
                                        <b>
                                            NEWS: </b>
                                    </p>
                                    <p>
                                        The news from various sources is aggregated and provided in this section to enable
                                        Cyber professionals to have a complete view of different sources in one place.
                                        <div>
                                            <img src="/Abouts_img2.png" alt="Cyber Security news" />
                                        </div>

                                    </p>
                                    <p>
                                        <b>
                                            BLOG:
                                        </b>
                                    </p>
                                    <p>
                                        We are creating Infographics and Blogs in this section on various topics that are cyber
                                        security news, ransomware attacks, data classification, network vulnerabilities, Data
                                        Protection, and many more. Our ultimate aim is to create awareness on the significant
                                        trends which is running across the industry.
                                        <div>
                                            <img src="/Abouts_img3.png" alt="Cyber Security blog" />
                                        </div>

                                    </p>
                                    <p>
                                        <b>
                                            CYBER VIDEOS:
                                        </b>
                                    </p>

                                    <p>

                                        Cyber Videos section focusses on presenting trending Cyber Videos across
                                        the internet for our users.
                                    </p>
                                    <p>
                                        <b>
                                            CYBER LAW:
                                        </b>
                                    </p>
                                    <p>
                                        Focus is to update our users with the current topics and key judgements on
                                        Cyber Law.
                                        <div>
                                            <img src="/Abouts_img4.png" alt="Cyber Law" />
                                        </div>

                                    </p>
                                    <p>
                                        <b>
                                            NEIGHBHOURHOOD ALERT:
                                        </b>
                                    </p>
                                    <p>
                                        It focusses primarily on the burglary and thefts happening
                                        around Buckinghamshire county to keep people informed of the threats.
                                        <div>
                                            <img src="/Abouts_img5.png" alt="Neihbhourhood Alert High Wycombe Buckinghamshire" />
                                        </div>

                                    </p>

                                    <p>
                                        <b>
                                            MALWARE:
                                        </b>
                                    </p>

                                    <p>
                                        Predominantly, this section will focus more on the Malware attacks and tools
                                        used for preventing the Malware from attacking the computers.
                                        <div>
                                            <img src="/Abouts_img6.png" alt="Malware Information security" />
                                        </div>
                                    </p>

                                    <p>
                                        TheSecureRadius.com is part of I3 Solutions UK LTD. TheSecureRadius is keen on improving and
                                        looking forward for any collaboration and promotions in the future.
                                    </p>


                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </SRBaseLayout>
        );
    }

}