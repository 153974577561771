import firebase from 'firebase/app';
import 'firebase/app-check';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import "firebase/performance";
import { showprogress } from "../../core/decorators";
import SRGenericContentAPI from "./SRGenericContentAPI";
import SRCommentsAPI from "./SRCommentsAPI";
import SRCommon from "./SRCommon";
import SRContentAPI from './SRContentAPI';
import SRNeighbourhoodAlertAPI from "./SRNeighbourhoodAlertAPI";
import SRNewsAPI from "./SRNewsAPI";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};


const using = (props: any, callback: (fb: SRFirebase) => any) => {
    callback(getFirebase(props));
}

const getFirebase = (props: any): SRFirebase => {
    if (Object.keys(props).includes('context') && props['context'] instanceof Map) {
        const context: Map<string, any> = props['context'];
        if (context) {
            return context.get('firebase');
        }
    }
    throw new Error('Firebase not found');
}

export { using, getFirebase };


export default class SRFirebase {
    public readonly srNewsAPI: SRNewsAPI | undefined;
    public readonly srCyberLawNewsAPI: SRContentAPI | undefined;
    public readonly srArticlesAPI: SRContentAPI | undefined;
    public readonly srCommon: SRCommon | undefined;
    public readonly srCommentsAPI: SRCommentsAPI | undefined;
    public readonly srNeighbourhoodAlertAPI: SRContentAPI | undefined;
    public readonly srMalwareNewsAPI: SRGenericContentAPI | undefined;

    constructor() {
        try {
            firebase.initializeApp(firebaseConfig);
            const appCheck = firebase.appCheck();
            firebase.performance();
            firebase.analytics();
            const firestore = firebase.firestore();
            if (process.env.NODE_ENV === 'development') {
                firestore.useEmulator("localhost", 8080);
                firebase.auth().useEmulator("http://localhost:9099");
                firebase.functions().useEmulator("localhost", 5001);
            } else {
                appCheck.activate('6Lfz0KMbAAAAACvBVFqfUcqb9fBgpzVth_7KptoS');
            }
            // firestore.enablePersistence().catch(r => console.error(`Persistence disabled - ${r}`));
            this.srCommon = new SRCommon(firestore, firebase.functions(), firebase.auth())
            console.info(firebase.auth().settings);
            firebase.auth().onAuthStateChanged(this.srCommon.authStateChanged);
            this.srNewsAPI = new SRNewsAPI(this.srCommon, firestore, firebase.functions(), firebase.auth());
            this.srNeighbourhoodAlertAPI = new SRNeighbourhoodAlertAPI(this.srCommon, firestore, firebase.functions(), firebase.auth());
            this.srArticlesAPI = new SRGenericContentAPI(this.srCommon, firestore, firebase.functions(), firebase.auth(), 'sr_articles', 'sr_staging_articles', 'sr_articles_tag_master');
            this.srCyberLawNewsAPI = new SRGenericContentAPI(this.srCommon, firestore, firebase.functions(), firebase.auth(), 'sr_cyber_law_news', 'sr_staging_cyber_law_news', 'sr_news_tag_master');
            this.srMalwareNewsAPI = new SRGenericContentAPI(this.srCommon, firestore, firebase.functions(), firebase.auth(), 'sr_malware_news', 'sr_staging_malware_news', 'sr_news_tag_master');
            this.srCommentsAPI = new SRCommentsAPI(this.srCommon, firestore, firebase.functions(), firebase.auth());
        } catch (error) {
            console.error(`Initialization failed - ${error}`);
        }
    }

    @showprogress()
    async doPasswordAuthenticate(email: string, password: string): Promise<firebase.User | null> {
        const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
        return userCredential.user;
    }

    @showprogress()
    async doSignOut() {
        try {
            await firebase.auth().signOut();
        } catch (e) {
            console.error(`Sign Out failed - ${e}`);
            throw e;
        }
    }

    @showprogress()
    async doSignup(email: string, password: string) {
        try {
            const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
            userCredential.user?.sendEmailVerification();
            await firebase.auth().signOut();
            localStorage.setItem('notification',
                JSON.stringify({
                    type: 'info', title: 'Verify Email',
                    message: `We have sent you a mail with instructions to verify/confirm your email.`
                }));
        } catch (error) {
            console.error(`Signup failed - ${error}`);
            throw error;
        }
    }

    @showprogress()
    async doVerifyEmail(code: string) {
        await firebase.auth().applyActionCode(code);
    }


    @showprogress()
    async doUpdate(password: string) {
        try {
            // await firebase.auth().pass;
        } catch (error) {
            console.error(`Signup failed - ${error}`);
            throw error;
        }
    }

    @showprogress()
    async saveContactUsMessage(email: string, name: string, message: string) {
        try {
            const database = firebase.firestore();
            return await database.collection('sr_contact_us')
                .add({
                    email,
                    name,
                    message,
                    created_at: firebase.firestore.Timestamp.now()
                });
        } catch (e) {
            console.error(`Failed to save message - ${e}`);
        }
    }

    @showprogress()
    async getSRNewsForDashboard() {
        try {
            const database = firebase.firestore();
            return await database.collection('sr_news')
                .limit(4)
                .orderBy('created_at', "desc").get();
        } catch (e) {
            console.error(`Fetch news failed - ${e}`);
        }
    }

    @showprogress()
    async getSRArticlesForDashboard() {
        try {
            const database = firebase.firestore();
            return await database.collection('sr_articles')
                .limit(4)
                .orderBy('created_at', "desc").get();
        } catch (e) {
            console.error(`Fetch news failed - ${e}`);
        }
    }

    @showprogress()
    async getSRBreakingNewsForDashboard() {
        try {
            const database = firebase.firestore();
            const documentSnapshot = await database.collection('sr_breaking_news').doc('items').get();
            return documentSnapshot.data()?.items;
        } catch (e) {
            console.error(`Fetch news failed - ${e}`);
        }
    }

    @showprogress()
    async getSRTrendingVideosForDashboard() {
        try {
            const database = firebase.firestore();
            return await database.collection('sr_trending_videos')
                .limit(5)
                .orderBy('created_at', "desc").get();
        } catch (e) {
            console.error(`Fetch trending videos failed - ${e}`);
        }
    }

    getAPI(name: string | undefined): SRContentAPI | undefined {
        switch (name) {
            case "articles":
                return this.srArticlesAPI;
            case "news":
                return this.srNewsAPI;
            case "neighbourhood":
                return this.srNeighbourhoodAlertAPI;
            case "cybernews":
                return this.srCyberLawNewsAPI;
            case "malware":
                return this.srMalwareNewsAPI;
            default:
                throw new Error(`API for ${name} not found`);
        }
    }

}
