import firebase from "firebase";
import { getFirebase } from "../shcontext/SRFirebase";
import AbstractListPage from "./AbstractListPage";
import SRCardRenderer from "./SRCardRenderer";

export default class ContentListPage extends AbstractListPage {

    async refreshTags() {
        const srFirebase = getFirebase(this.props);
        if (srFirebase) {
            let news_tags: firebase.firestore.DocumentData[] = [];
            let tags = await srFirebase.getAPI(this.props.api)?.fetchTags();
            if (tags) {
                tags.forEach(t => {
                    const data = t.data();
                    data.id = t.id;
                    news_tags.push(data);
                })
                this.setState({tags: news_tags});
            }
        }
    }

    async refreshItems() {
        const srFirebase = getFirebase(this.props);
        if (srFirebase) {
            let news_items: firebase.firestore.DocumentData[] = [];
            let tags: string[] = [];
            this.state.selectedTags.forEach(v => tags.push(v));

            let news = await srFirebase.getAPI(this.props.api)?.fetchContents(this.state.last_doc_snapshot, this.state.page_size, tags);
            if (news) {
                news.data.forEach(doc => {
                    const data = doc.data();
                    data.id = doc.id;
                    news_items.push(data);
                });
                if(this.state.last_doc_snapshot) {
                    const existingItems: any[] = [];
                    existingItems.push(...this.state.items);
                    existingItems.push(...news_items);
                    news_items  = existingItems;
                }
                this.setState({
                    items: news_items,
                    last_doc_snapshot: news.data.docs[news.data.size - 1]
                });
            }
        }
    }

    protected renderListItem(i: any) {
        return (
            i.source_url
                    ? <SRCardRenderer key={i.title} item={i} href={i.source_url} target="_blank"/>
                    : <SRCardRenderer key={i.title} item={i} href={`/${this.props.routePath}/${i.id}`}/>
        )
    }
}