import React from "react";
import SRBaseLayout from "../SRBaseLayout";
import { Badge, Col, Container, Row } from "react-bootstrap";
import { RouteComponentProps } from "react-router";
import SRCardRenderer from "./SRCardRenderer";
import SRTagFilterBar from "./SRTagFilterBar";
import InfiniteScroll from "react-infinite-scroll-component";
import ConfirmDialog from "../ConfirmDialog";
import { ContentProps } from "../shcontext/context";

interface ListProps extends RouteComponentProps, ContentProps {
    title: string;
    category_title: string;
    noItemsMessage: string;
}

export default abstract class AbstractListPage extends React.Component<ListProps> {

    state = {
        items: [],
        tags: [],
        selectedTags: [],
        last_doc_snapshot: null,
        page_size: 20,
        confirmDialogRef: React.createRef<ConfirmDialog>(),
        extension: new Map<string, any>(),

        tagsSelectionChanged: (selectedTags: Set<string>) => {
            const new_tags: string[] = [];
            selectedTags.forEach(v => new_tags.push(v));
            this.setState({ selectedTags: new_tags, last_doc_snapshot: null }, () => {
                this.refreshItems();
            });
        }
    }

    abstract refreshItems(): unknown;

    abstract refreshTags(): unknown;

    clearState() {
        this.setState({
            items: [],
            tags: [],
            selectedTags: [],
            last_doc_snapshot: null,
            page_size: 20,
        });
    }

    render() {
        return (
            <SRBaseLayout>
                <Row>
                    <Col md={2} sm={12}>
                        <SRTagFilterBar name={this.props.title}
                            title={this.props.category_title}
                            tags={this.state.tags}
                            tags_selected={this.state.selectedTags}
                            onSelectionChange={this.state.tagsSelectionChanged}
                        />
                    </Col>
                    <Col md={8} sm={12}>
                        <Container fluid>
                            {
                                this.props.title ?
                                    <Badge className="m-1 p-1" variant="primary">
                                        <p className="h4 m-0 p-0 text-light">{this.props.title}</p>
                                    </Badge>
                                    : ''
                            }
                            <Row><Col md={12}>{this.renderActionBar()}</Col></Row>
                            <Row>
                                <InfiniteScroll
                                    dataLength={this.state.items.length}
                                    next={this.nextItems}
                                    hasMore={this.state.items.length % this.state.page_size === 0}
                                    loader=""
                                >
                                    {
                                        this.state.items.length > 0 ?
                                            this.renderListWrapper()
                                            :
                                            <Col className="mt-3">
                                                {this.props.noItemsMessage || 'No items found!'}
                                            </Col>
                                    }
                                </InfiniteScroll>
                            </Row>
                            {/*{*/}
                            {/*    this.state.items.length > 0 ?*/}
                            {/*        <Row className="mt-3 mb-3">*/}
                            {/*            <Col md={12}>*/}
                            {/*                <SRPagination current_page={this.state.current_page}*/}
                            {/*                              total_pages={this.state.total_pages}*/}
                            {/*                              navigateTo={this.state.navigateTo}/>*/}
                            {/*            </Col>*/}
                            {/*        </Row>*/}
                            {/*        :*/}
                            {/*        ''*/}
                            {/*}*/}
                        </Container>
                    </Col>
                </Row>
                <ConfirmDialog ref={this.state.confirmDialogRef} />
            </SRBaseLayout>
        );
    }


    protected renderListWrapper() {
        return <Col md={12} sm={12}>
            <Row className="crap">
                {
                    this.state.items.map((i: any) => {
                        return this.renderListItem(i)
                    })
                }
            </Row>
        </Col>;
    }

    protected renderListItem(i: any) {
        return (
            <SRCardRenderer key={i.title} item={i} />
        )
    }

    protected renderActionBar(): JSX.Element {
        return (<div></div>)
    }

    componentDidMount() {
        console.info(`List mounted`);
        this.reload();
    }

    reload() {
        this.setState({ items: [], last_doc_snapshot: null },
            async () => {
                console.info(`Mounted, items - ${this.state.items.length}`);
                await this.refreshTags();
                await this.refreshItems();
            });
    }

    componentWillUnmount() {
        this.clearState();
    }

    nextItems = async () => {
        if (this.state.items.length > 0) {
            await this.refreshItems();
        }
    }

    confirm = (msg: string, okFun: () => void, canFun: () => void) => {
        this.state.confirmDialogRef.current?.confirm(msg, okFun, canFun);
    }

}