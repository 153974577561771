import React from "react";
import { Badge, Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import { withContext } from "../shcontext";
import { getFirebase } from "../shcontext/SRFirebase";
import SRBaseLayout from "../SRBaseLayout";
import DashboardRow from "./DashboardRow";

const DashboardRowWithContext = withContext(DashboardRow);

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

export default class SRHome extends React.Component {

    state = {
        breaking_news: [],
        trending_videos: []
    }

    times(str: string, count: number): string {
        let c = 0;
        let buff = '';
        do {
            buff += str;
            c++;
        } while (c < count);
        return buff;
    }

    render() {
        return (
            <SRBaseLayout>
                <Container fluid>
                    {/* <Row>
                        <Col md={12} xs={12}>
                            {
                                this.state.breaking_news.length > 0
                                    ? <BreakingNews breakingNews={this.state.breaking_news} />
                                    : ''
                            }

                        </Col>
                    </Row> */}
                    <Row className="pt-5 pb-3">
                        <Col md={2}></Col>
                        <Col md={8}>
                            <Row className="">
                                <Col md={6}>
                                    <div className="lead display-5 m-5 p-3 font-monospace fs-2">
                                        <p className="text-center"><strong>Information is Wealth</strong></p>
                                        <p className="text-center">TheSecureRadius provides Cyber Security News and Information for Cyber professionals</p>
                                    </div>
                                </Col>
                                <Col md={5}>
                                    <iframe width="100%" height="100%" src={`https://www.youtube.com/embed/jFdSnnL992A`} frameBorder="0"></iframe>
                                </Col>
                                <Col md={1}></Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="pt-5 pb-2">
                        <Col md={2}></Col>
                        <Col xs={12} md={8}>
                            <div className="dashboard_badge bg-primary">
                                Trending Videos
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: "10px" }}>
                        <Col md={2}></Col>
                        <Col md={8}>
                            <Carousel responsive={responsive} autoPlay={true} infinite={true}>
                                {
                                    this.state.trending_videos.map((n: any) => {
                                        return (
                                            <iframe width="95%" height="95%" src={n.video_url} frameBorder="0"></iframe>
                                        )
                                    })
                                }
                            </Carousel>
                        </Col>
                    </Row>
                    <DashboardRowWithContext badgeText="News"
                        itemSource={this.fetchSRNewsForDashboard}
                        baseUrl="/news"
                    />
                    <DashboardRowWithContext badgeText="Blog"
                        itemSource={this.fetchSRArticlesForDashboard}
                        baseUrl="/blog"
                    />
                </Container>
            </SRBaseLayout>
        )
    }

    async componentDidMount() {
        const firebase = getFirebase(this.props);
        if (firebase) {
            let items = await firebase.getSRBreakingNewsForDashboard();
            if (items) {
                this.setState({
                    breaking_news: items
                });
            }

            let videos = await firebase.getSRTrendingVideosForDashboard();
            if (videos) {
                const trendingVideos: any[] = [];
                videos.forEach(doc => {
                    const data = doc.data();
                    data.id = doc.id;
                    trendingVideos.push(data);
                });
                this.setState({
                    trending_videos: trendingVideos
                });
            }
        }
    }

    async fetchSRNewsForDashboard(props: any): Promise<any[]> {
        const firebase = getFirebase(props);
        let news_items: any[] = [];
        if (firebase) {
            let news = await firebase.getSRNewsForDashboard();
            if (news) {
                news.forEach(doc => {
                    const data = doc.data();
                    data.id = doc.id;
                    news_items.push(data);
                });
            }
        }
        return news_items;
    }

    async fetchSRArticlesForDashboard(props: any): Promise<any[]> {
        const firebase = getFirebase(props);
        let news_items: any[] = [];
        if (firebase) {
            let news = await firebase.getSRArticlesForDashboard();
            if (news) {
                news.forEach(doc => {
                    const data = doc.data();
                    data.id = doc.id;
                    news_items.push(data);
                });
            }
        }
        console.info(`SR Articles - ${JSON.stringify(news_items)}`);
        return news_items;
    }
}