import React from "react";
import SREventBus from "../core/SREventBus";
import {Modal, Spinner} from "react-bootstrap";

export default class PageProgress extends React.Component {

    state = {
        showLoader: false
    }

    private progressCount = 0;

    render() {
        return <div>
            <Modal show={this.state.showLoader} backdrop="static" keyboard={false} centered>
                <Modal.Body>
                        <Spinner as="span" animation="grow" size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        Loading...
                </Modal.Body>
            </Modal>
        </div>;
    }

    componentDidMount() {
        SREventBus.on('showProgress', () => {
            this.progressCount++;
            this.setState({showLoader: true});
            console.debug(`Show Progress count - ${this.progressCount}`);
        });

        SREventBus.on('hideProgress', () => {
            if (this.progressCount > 0) {
                this.progressCount--;
            }
            if (this.progressCount < 1) {
                this.setState({showLoader: false});
            }
            console.debug(`Hide Progress count - ${this.progressCount}`);
        });

        SREventBus.on('clearProgress', () => {
            this.progressCount = 0;
            this.setState({showLoader: false});
        });
    }
}