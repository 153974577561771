import {Badge, Button, Nav, Navbar} from "react-bootstrap";
import React from "react";

interface TagFilterProps {
    name: string;
    title?: string;
    tags: string[];
    tags_selected?: string[];
    onSelectionChange: (selectedTags: Set<string>) => void;
    horizontal?: boolean;
}

export default class SRTagFilterBar extends React.Component<TagFilterProps> {

    state = {
        onSelectTag: (selectTag: {name: string, value: string | undefined}) => {
            console.info(`Selected Tag - ${JSON.stringify(selectTag)}`);
            const tags = new Set<string>(this.props.tags_selected ? this.props.tags_selected : []);
            const tag = selectTag.value ? selectTag.value : selectTag.name;
            if (tags.has(tag)) {
                tags.delete(tag);
            } else {
                tags.add(tag);
            }

            if (this.props.onSelectionChange != null) {
                console.debug(`Selected tags - ${JSON.stringify(Array.from(tags))}`);
                this.props.onSelectionChange(tags);
            }

        },
        isSelected: (tag: any) => {
            return this.props.tags_selected?.includes(tag);
        },
    }

    render() {
        let id_value = `${this.props.name}-navbar-nav`;
        return (
            <Navbar expand="lg">
                <Navbar.Toggle aria-controls={id_value}/>
                <Navbar.Collapse id={id_value}>
                    <Nav className={this.props.horizontal ? 'flex-row w-100' : 'flex-column w-100'}>
                        <Badge className="mt-2 mr-2 text-left card-title">
                            <h5>{this.props.title || 'Categories'}</h5>
                        </Badge>
                        {
                            this.props.tags.length > 0 ?
                                this.props.tags.map((t: any) => {
                                    return (
                                        <Nav.Item className="mt-2 mr-2 w-100" key={t.name}>
                                            <Button
                                                className="w-100 text-left"
                                                variant="outline-primary"
                                                onClick={() => this.state.onSelectTag(t)}>
                                                {
                                                    this.state.isSelected(t.value ? t.value : t.name) ?
                                                        <span>
                                                            <i className="fas fa-check-square mr-1"></i>{t.name}
                                                        </span>
                                                        :
                                                        <span>
                                                            <i className="fas fa-square mr-1"></i>{t.name}
                                                        </span>
                                                }

                                            </Button>
                                        </Nav.Item>
                                    )
                                })
                                :
                                <div>Loading....</div>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }

}