import React, {FormEvent} from "react";
import {Alert, Button, Card, Col, Container, Form, FormGroup, Row} from "react-bootstrap";
import SRBaseLayout from "./SRBaseLayout";
import {RouteComponentProps} from "react-router";
import {getFirebase} from "./shcontext/SRFirebase";
import SREventBus from "../core/SREventBus";
import ConfirmDialog from "./ConfirmDialog";

export default class SRProfile extends React.Component<RouteComponentProps> {

    state = {
        email: '',
        password: '',
        confirm_password: '',
        handleChange: (e: any) => {
            let {name, value} = e.target;
            this.setState({[name]: value});
        },
        errors: [],
        confirmDialog: React.createRef<ConfirmDialog>()
    };

    render() {
        return (
            <SRBaseLayout>
                <Container>
                    <Row>
                        <Col md={8}>
                            <Card>
                                <Card.Title className="mt-3 ml-3">Profile</Card.Title>
                                <Card.Body>
                                    <Form onSubmit={this.update}>
                                        <FormGroup>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Text className="lead">
                                                {this.state.email}
                                            </Form.Text>
                                        </FormGroup>
                                        <FormGroup>
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="password" placeholder="Password" name="password"
                                                          value={this.state.password}
                                                          onChange={this.state.handleChange}>
                                            </Form.Control>
                                        </FormGroup>
                                        <FormGroup>
                                            <Form.Label>Confirm Password</Form.Label>
                                            <Form.Control type="password" placeholder="Confirm Password"
                                                          name="confirm_password"
                                                          value={this.state.confirm_password}
                                                          onChange={this.state.handleChange}>
                                            </Form.Control>
                                        </FormGroup>
                                        <FormGroup>
                                            <Container>
                                                <Row>
                                                    <Col>
                                                        <Button type="submit" >Update</Button>
                                                    </Col>
                                                    <Col>
                                                        <Button type="button" className="btn-danger"
                                                                onClick={this.confirmDelete}>
                                                            Delete Account
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </FormGroup>
                                    </Form>
                                </Card.Body>
                                {
                                    this.state.errors.length > 0
                                        ? <Card.Footer>
                                            {
                                                this.state.errors.map(e => {
                                                    return (
                                                        <Alert variant="danger">{e}</Alert>
                                                    )
                                                })
                                            }
                                        </Card.Footer>
                                        : ''
                                }

                            </Card>
                        </Col>
                    </Row>
                    <ConfirmDialog ref={this.state.confirmDialog}></ConfirmDialog>
                </Container>
            </SRBaseLayout>
        );
    }

    update = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if(this.state.password !== this.state.confirm_password) {
                throw new Error('Passwords do not match');
            }
            const firebase = getFirebase(this.props);
            if (firebase) {
                // await firebase.
                this.setState({errors: []});
                this.props.history.push('/');
            }
        } catch (err) {
            let message = 'Some error occurred. Please try again'
            if (err.code === 'auth/user-not-found') {
                message = "User not found or password doesn't match";
            }
            this.setState({errors: [message]});
        }
    }

    componentDidMount() {
        SREventBus.on('USER_SIGNED_IN', (event: CustomEvent) => {
            const user = event.detail;
            console.info(`SRProfile - user signed in - ${user.email}`);
            this.setState({email: user.email, errors: []});
        });

        SREventBus.on('USER_SIGNED_OUT', (event: CustomEvent) => {
            console.info(`SRProfile - user signed out`);
            this.props.history.push('/');
        });
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.error(`SRProfile error - ${error} - ${errorInfo}`);
    }

    confirmDelete = () => {
        this.state.confirmDialog.current?.confirm('Are you sure to delete your Account?',
            this.deleteAccount,
            () => {
            });
    }

    deleteAccount = async () => {
        const firebase = getFirebase(this.props);
        if (firebase && firebase.srCommon?.isAuthenticated()) {
                firebase.srCommon?.currentUser()?.delete().then(() => {
                    localStorage.setItem('notification',
                        JSON.stringify({type: 'success', title: 'Delete Account', message: 'Account deleted successfully'}));
                }).catch(reason => {
                    SREventBus.dispatch('SHOW_NOTIFICATION',
                        {type: 'danger', title: 'Delete Account', message: `Sorry! Your account could not be deleted - ${reason}`});
                });
        }
    }
}