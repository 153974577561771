import firebase from "firebase/app";
import {showprogress} from "../../core/decorators";
import SRCommon from "./SRCommon";

export default class SRCommentsAPI {
    constructor(private readonly srCommon: SRCommon,
                private readonly firestore: firebase.firestore.Firestore,
                private readonly functions: firebase.functions.Functions,
                private readonly auth: firebase.auth.Auth) {
    }

    @showprogress()
    async fetchSRComments(start: firebase.firestore.DocumentSnapshot<any> | null, size: number, entity: string, entityId: string) {
        let collectionReference = this.firestore.collection('sr_comments');
        let query = collectionReference.where('entityId', '==', entityId)
            .where('entity', '==', entity)
            .orderBy('created_at', 'asc');
        if (start) {
            query = query.startAfter(start);
        }
        const dataSnapshot = await query.limit(size).get();
        return {
            data: dataSnapshot
        };
    }

    @showprogress()
    async fetchSRCommentItem(id: string) {
        return this.firestore.collection('sr_comments').doc(id).get();
    }

    @showprogress()
    async saveCommentItem(id: string | undefined | null, comment: {content: string, entity: string, entityId: string}) {
        const collectionReference = this.firestore.collection('sr_comments');
        const fullComment: any = comment;
        const email = this.auth.currentUser?.email;
        const matches = email?.match(/(.*)@.*\..*/);
        if(matches) {
            fullComment.author = matches[1];
        }
        fullComment.uid = this.auth.currentUser?.uid;
        fullComment.modified_at = firebase.firestore.Timestamp.now();
        if (id) {
            await collectionReference.doc(id).set(fullComment);
        } else {
            fullComment.created_at = firebase.firestore.Timestamp.now();
            const documentReference = await collectionReference.add(fullComment);
            id = documentReference.id;
        }
        return await collectionReference.doc(id).get();
    }

}