import React from "react";
import { Alert, Modal, Toast } from "react-bootstrap";
import SREventBus from "../core/SREventBus";

export default class Notification extends React.Component {

    state = {
        showDialog: false,
        title: '',
        text: '',
        type: ''
    }

    render() {
        return <Modal show={this.state.showDialog} backdrop="static" keyboard={false} centered className="p-0 m-0">
            <Modal.Body>
                <Toast show={this.state.showDialog}
                    onClose={this.clearState}
                    delay={1000}
                    style={{ minWidth: "100%" }}>
                    <Toast.Header>
                        <strong className="mr-auto">{this.state.title}</strong>
                    </Toast.Header>
                    <Toast.Body>
                        <Alert variant={this.state.type}>
                            {this.state.text}
                        </Alert>
                    </Toast.Body>
                </Toast>
            </Modal.Body>

        </Modal>;
    }


    clearState = () => {
        this.setState({
            showDialog: false,
            text: '',
            title: '',
            type: ''
        });
    }

    componentDidMount() {
        SREventBus.on('SHOW_NOTIFICATION', (event: CustomEvent) => {
            const { title, message, type } = event.detail;
            console.info(`${type} Notification - ${title} -> ${message}`);
            this.setState({
                showDialog: true,
                text: message,
                title: title,
                type: type
            });
        });
    }
}