import SREventBus from "./SREventBus";

export function showprogress() {
    return (target: any, propertyKey: string,
            descriptor: PropertyDescriptor) => {
        const method = descriptor.value;
        descriptor.value = async function (...args: any) {
            console.debug(`Show Progress - ${method.name}`);
            SREventBus.dispatch('showProgress');
            try {
                const data = await method.apply(this, args);
                return data;
            } finally {
                console.debug(`Hide Progress - ${method.name}`);
                SREventBus.dispatch('hideProgress');
            }
        }
        return descriptor;
    }
}
