import firebase from "firebase/app";
import {showprogress} from "../../core/decorators";
import {createQuery} from "./util";
import SRCommon from "./SRCommon";
import SRContentAPI from "./SRContentAPI";


export default class SRGenericContentAPI implements SRContentAPI{
    constructor(private readonly srCommon: SRCommon,
                private readonly firestore: firebase.firestore.Firestore,
                private readonly functions: firebase.functions.Functions,
                private readonly auth: firebase.auth.Auth,
                private readonly collectionName: string,
                private readonly stagingCollectionName: string,
                private readonly tagCollectionName: string) {
    }

    @showprogress()
    async fetchContents(start: firebase.firestore.DocumentSnapshot<any> | null, size: number, tags: string[]) {
        let collectionReference = this.firestore.collection(this.collectionName);
        let query = createQuery(tags, collectionReference);
        if (start) {
            query = query.startAfter(start);
        }
        const dataSnapshot = await query.limit(size).get();
        return {
            data: dataSnapshot
        };
    }

    @showprogress()
    async fetchStagingContents(start: firebase.firestore.DocumentSnapshot<any> | null, size: number, tags: string[]) {
        let collectionReference = this.firestore.collection(this.stagingCollectionName);
        let query = createQuery(tags, collectionReference);
        if (start) {
            query = query.startAfter(start);
        }

        const isAdmin = await this.srCommon.isUserAdmin();
        if(!isAdmin) {
            query = query.where('uid', '==', this.auth.currentUser?.uid)
        }

        const dataSnapshot = await query.limit(size).get();
        return {
            data: dataSnapshot
        };
    }

    @showprogress()
    async fetchStagedContent(id: string) {
        return this.firestore.collection(this.stagingCollectionName).doc(id).get();
    }

    @showprogress()
    async fetchContent(id: string) {
        return this.firestore.collection(this.collectionName).doc(id).get();
    }

    @showprogress()
    async publishContent(id: string) {
        const sr_articles_collection = this.firestore.collection(this.collectionName);
        const stagedArticle = await this.firestore.collection(this.stagingCollectionName).doc(id).get();
        const sr_article = await sr_articles_collection
            .where('sr_source_id', '==', id)
            .get();
        if(sr_article.size > 0) {
            //update
            const sr_article_doc = sr_article.docs[0].data();
            sr_article_doc.title = stagedArticle?.data()?.title;
            sr_article_doc.content = stagedArticle?.data()?.content;
            sr_article_doc.summary = stagedArticle?.data()?.summary;
            sr_article_doc.image = stagedArticle?.data()?.image ? stagedArticle?.data()?.image : '';
            sr_article_doc.created_at = firebase.firestore.Timestamp.now();
            sr_article_doc.author = stagedArticle?.data()?.author;
            sr_article_doc.tags = stagedArticle?.data()?.tags;

            sr_articles_collection.doc(sr_article.docs[0].id).set(sr_article_doc);
        } else {
            //add
            sr_articles_collection.add({
                title: stagedArticle?.data()?.title,
                content: stagedArticle?.data()?.content,
                summary: stagedArticle?.data()?.summary,
                image: stagedArticle?.data()?.image ? stagedArticle?.data()?.image : '',
                created_at: firebase.firestore.Timestamp.now(),
                author: stagedArticle?.data()?.author,
                sr_source_id: id,
                tags: stagedArticle?.data()?.tags
            });
        }

        const data = Object.assign(stagedArticle.data(), {published: true});
        await this.firestore.collection(this.stagingCollectionName)
            .doc(id).set(data);

    }

    @showprogress()
    async saveContentInStaging(article: any, id: string | null): Promise<string> {
        const collectionReference = this.firestore.collection(this.stagingCollectionName);
        if (id) {
            await collectionReference.doc(id).set(article);
            return id;
        } else {
            const documentReference = await collectionReference.add(article);
            return documentReference.id;
        }
    }

    @showprogress()
    async fetchTags() {
        const collectionReference = this.firestore.collection(this.tagCollectionName);
        return await collectionReference.get();
    }

    @showprogress()
    async deleteContent(id: string) {
        try {
            const documentReference = await this.firestore.collection(this.stagingCollectionName).doc(id);
            await documentReference.delete();
        } catch (e) {
            console.error(`Error deleting article - ${e}`);
        }
    }
}