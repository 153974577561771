import React from "react";
import {Nav, Navbar} from "react-bootstrap";

export default class SRFooter extends React.Component {

    state = {}

    render() {
        return (
            <div>
                <Navbar bg="secondary" variant="light" expand="lg" className="justify-content-center">
                    <Nav.Link href="/contact_us">Contact Us</Nav.Link>
                    <Nav.Link href="https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32016R0679"
                              target="_blank">
                        GDPR
                    </Nav.Link>
                    <Nav.Link
                        href="https://firebasestorage.googleapis.com/v0/b/radius-aa6ae.appspot.com/o/documents%2FTheSecureRadius_Cookie_Policy.pdf?alt=media&token=f4c5d5c6-237e-4766-b03f-1c0ae6ab7c80"
                        target="_blank">
                        Cookie Policy
                    </Nav.Link>
                    <Nav.Link href="https://eur-lex.europa.eu/legal-content/EN/ALL/?uri=CELEX%3A32002L0058"
                              target="_blank">
                        e-Privacy
                    </Nav.Link>
                    <Nav.Link
                        href="https://firebasestorage.googleapis.com/v0/b/radius-aa6ae.appspot.com/o/documents%2FTheSecureRadius_Privacy_Policy.pdf?alt=media&token=dc4eef64-ef49-4832-ae48-2f2cb551b487"
                        target="_blank">
                        Privacy Policy
                    </Nav.Link>
                    <Nav.Link
                        href="https://firebasestorage.googleapis.com/v0/b/radius-aa6ae.appspot.com/o/documents%2FTheSecureRadius_Terms_And_Conditions_Policy.pdf?alt=media&token=890e8bb0-d6b0-4ab0-b1e0-0b1b6f94ad80"
                        target="_blank">
                        Terms & Conditions
                    </Nav.Link>
                    <Nav.Link href="https://pixabay.com" target="_blank">Images courtesy of Pixabay.com</Nav.Link>
                </Navbar>
                <Navbar variant="light" expand="lg" className="justify-content-center small">
                    <Nav.Item>
                        Copyright &copy; {new Date().getFullYear()}. I3OT Ltd. All rights reserved.
                    </Nav.Item>
                </Navbar>
            </div>
        );
    }

    componentDidMount() {

    }

}