import React from "react";

const SRContext = React.createContext<Map<string, unknown>>(new Map());

export type ContentProps = {
    api?: string;
    routePath? : string;
    contentName? : string;
}

const withContext = (Component: any, config?: ContentProps) => (props: any) => {
    return (
        <SRContext.Consumer>
            {context => <Component {...props} {...config} context={context}/>}
        </SRContext.Consumer>
    )
}

export { withContext };
export default SRContext;
