import React, {FormEvent} from "react";
import {Alert, Button, Card, Col, Container, Form, FormGroup, Row} from "react-bootstrap";
import SRBaseLayout from "./SRBaseLayout";
import {getFirebase} from "./shcontext/SRFirebase";
import {RouteComponentProps} from "react-router";


export default class SRSignUp extends React.Component<RouteComponentProps> {

    state = {
        email: '',
        password: '',
        confirm_password: '',
        handleChange: (e: any) => {
            let {name, value} = e.target;
            this.setState({[name]: value});
        },
        errors: []
    };

    render() {
        return (
            <SRBaseLayout>
                <Container>
                    <Row>
                        <Col md={6}>
                            <Card>
                                <Card.Title>Sign Up</Card.Title>
                                <Card.Body>
                                    <Form onSubmit={this.signUp}>
                                        <FormGroup>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="email" placeholder="Your email" name="email"
                                                          value={this.state.email} onChange={this.state.handleChange}>
                                            </Form.Control>
                                        </FormGroup>
                                        <FormGroup>
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="password" placeholder="Password" name="password"
                                                          value={this.state.password}
                                                          onChange={this.state.handleChange}>
                                            </Form.Control>
                                        </FormGroup>
                                        <FormGroup>
                                            <Form.Label>Confirm Password</Form.Label>
                                            <Form.Control type="password" placeholder="Confirm Password" name="confirm_password"
                                                          value={this.state.confirm_password}
                                                          onChange={this.state.handleChange}>
                                            </Form.Control>
                                        </FormGroup>
                                        <FormGroup>
                                            <Button type="submit">Sign Up</Button>
                                        </FormGroup>
                                    </Form>
                                </Card.Body>
                                {
                                    this.state.errors.length > 0
                                        ? <Card.Footer>
                                            {
                                                this.state.errors.map(e => {
                                                    return (
                                                        <Alert variant="danger">{e}</Alert>
                                                    )
                                                })
                                            }
                                        </Card.Footer>
                                        : ''
                                }

                            </Card>
                        </Col>
                    </Row>
                </Container>
            </SRBaseLayout>
        );
    }

    signUp = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if(this.state.password !== this.state.confirm_password) {
                throw new Error('Passwords do not match');
            }
            const firebase = getFirebase(this.props);
            if (firebase) {
                await firebase.doSignup(this.state.email, this.state.password);
                this.setState({errors: []});
                this.props.history.push('/');
            }
        } catch (err) {
            let message = 'Some error occurred. Please try again'
            /*
            auth/email-already-in-use
                Thrown if there already exists an account with the given email address.
            auth/invalid-email
                Thrown if the email address is not valid.
            auth/operation-not-allowed
                Thrown if email/password accounts are not enabled. Enable email/password accounts in the Firebase Console, under the Auth tab.
            auth/weak-password
                Thrown if the password is not strong enough.
             */
            if(err.message) {
                message = err.message;
            }
            this.setState({errors: [message]});
        }
    }

    componentDidMount() {
        this.setState({errors: []});
    }
}