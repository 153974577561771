import {Card, Container, Nav, Navbar} from "react-bootstrap";
import React from "react";
import {getFirebase} from "../shcontext/SRFirebase";
import {RouteComponentProps} from "react-router";
import ReactQuill from "react-quill";
import SREventBus from "../../core/SREventBus";

interface CommentListProps extends RouteComponentProps {
    entity: string
    entityId: string
}

export default class CommentList extends React.Component<CommentListProps> {

    state = {
        comments: [],
        currentSnapshot: null,
        comment: '',
        user: null
    }

    onContentChange = (value: string) => {
        this.setState({comment: value});
    }

    addComment = async () => {
        const srFirebase = getFirebase(this.props);
        const commentDoc = await srFirebase.srCommentsAPI?.saveCommentItem(null, {
            content: this.state.comment,
            entity: this.props.entity,
            entityId: this.props.entityId
        });
        if (commentDoc) {
            const all_comments = [];
            all_comments.push(...this.state.comments);
            let newComment: any = commentDoc.data();
            newComment.id = commentDoc.id;
            all_comments.push(newComment);
            this.setState({comments: all_comments, comment: ''});
        }
    }

    render() {

        return (
            <Container className="mt-5 ml-0 pl-0">
                <h5>Comments</h5>
                {
                    this.state.comments && this.state.comments.length > 0 ?
                        this.state.comments.map((c: any) => {
                            return <Card className="mb-3" key={c.id}>
                                <Card.Text className="m-2" dangerouslySetInnerHTML={{__html: c.content}}>
                                </Card.Text>
                                <Card.Footer className="small">
                                    <span className="float-left ">{c.author}</span>
                                    <span className="float-right">
                                    {new Date(c.created_at.seconds * 1000).toLocaleDateString()}
                                </span>
                                </Card.Footer>
                            </Card>
                        })
                        :
                        <div>Be the first to comment</div>
                }
                {
                    this.state.user ?
                        <Card>
                            <Card.Header>Your Comment</Card.Header>
                            <Card.Body>
                                <ReactQuill theme="snow"
                                            value={this.state.comment}
                                            onChange={this.onContentChange}
                                            modules={{
                                                toolbar: {
                                                    container: [
                                                        [{header: [1, 2, 3, 4, 5, 6]}, {font: []}],
                                                        [{size: []}],
                                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                        [{list: 'ordered'}, {list: 'bullet'}],
                                                        ['link', 'image'],
                                                        ['clean'],
                                                        ['code-block']
                                                    ]
                                                }
                                            }}
                                            className="sr_quill_comments mb-5"
                                />
                                <Navbar>
                                    <Nav className="justify-content-end mr-4">
                                        <Nav.Link className="primary" onClick={this.addComment}>
                                            Add Comment
                                        </Nav.Link>
                                    </Nav>
                                </Navbar>
                            </Card.Body>
                        </Card>
                        : ''
                }

            </Container>
        );
    }

    async componentDidMount() {
        SREventBus.on('USER_SIGNED_IN', (event: CustomEvent) => {
            const user = event.detail;
            this.setState({user: user});
        });

        SREventBus.on('USER_SIGNED_OUT', (event: CustomEvent) => {
            this.setState({user: null});
        });

        const srFirebase = getFirebase(this.props);
        const srComments = await srFirebase.srCommentsAPI?.fetchSRComments(this.state.currentSnapshot,
            20, this.props.entity, this.props.entityId);
        let comments: any[] = [];
        srComments?.data.forEach(doc => {
            const data = doc.data();
            data.id = doc.id;
            comments.push(data);
        });

        if (this.state.currentSnapshot) {
            const all_comments: any[] = [];
            all_comments.push(...this.state.comments);
            all_comments.push(...comments);
            comments = all_comments;
        }
        this.setState({
            comments: comments,
            currentSnapshot: srComments?.data.docs[srComments?.data.size - 1]
        });
    }
}