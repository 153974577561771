import React from "react";
import {Alert, Card, Col, Container, Row} from "react-bootstrap";
import SRBaseLayout from "./SRBaseLayout";
import {getFirebase} from "./shcontext/SRFirebase";
import {RouteComponentProps} from "react-router";
import SREventBus from "../core/SREventBus";


export default class SRVerifyCode extends React.Component<RouteComponentProps> {

    state = {
        verified: false,
        errors: []
    };

    render() {
        return (
            <SRBaseLayout>
                <Container>
                    <Row>
                        <Col md={6}>
                            {
                                this.state.verified ?
                                    <Card>
                                        <Card.Title>Successfully verified</Card.Title>
                                        <Card.Body>
                                            Thank you. You have successfully verified your email.
                                        </Card.Body>
                                    </Card>
                                    : ''
                            }
                            {
                                this.state.errors.length > 0
                                    ? <div>
                                        {
                                            this.state.errors.map(e => {
                                                return (
                                                    <Alert variant="danger" key={e}>{e}</Alert>
                                                )
                                            })
                                        }
                                    </div>
                                    : ''
                            }

                        </Col>
                    </Row>
                </Container>
            </SRBaseLayout>
        );
    }

    async componentDidMount() {
        try {
            const firebase = getFirebase(this.props);
            if (firebase) {
                const urlSearchParams = new URLSearchParams(this.props.location.search);
                const code = urlSearchParams.get('oobCode');
                if (code == null) {
                    throw new Error('Invalid Code');
                }
                await firebase.doVerifyEmail(code);
                this.setState({verified: true, errors: []});
                SREventBus.dispatch('SHOW_NOTIFICATION',
                    {type: 'info', title: 'Email Verified',
                        message: `Please sign into your new account.`});
                this.props.history.push('/login');
            }
        } catch (err) {
            let message = 'Some error occurred. Please try again'
            if (err.message) {
                message = err.message;
            }
            this.setState({verified: false, errors: [message]});
        }
    }

}