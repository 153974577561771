import React from "react";
import { Card, Col, Image, Nav, Row } from "react-bootstrap";

export default class SRCardRenderer extends React.Component<{ item: any, href?: string, target?: string }> {
    render() {
        return <Col md={4}>
            <Card className="mt-2 newsitem">
                <Card.Title
                    className="ml-2 mt-2 font-weight-bold">
                    {
                        this.props.href ?
                            <Nav.Link href={this.props.href} target={this.props.target}>
                                {this.props.item.title}
                            </Nav.Link>
                            : this.props.item.title
                    }
                </Card.Title>
                <Card.Body>
                    <Row>
                        {
                            this.props.item.image ?
                                <Col md={12} sm={12}>
                                    <Image src={this.props.item.image}
                                        width="100%"></Image>
                                </Col>
                                : ""
                        }
                        <Col md={12} sm={12}
                            className="justify-content-around text-justify">
                            <div
                                dangerouslySetInnerHTML={{ __html: this.props.item.summary || this.props.item.content?.substring(0, 200) }}></div>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className="small">
                    <span className="float-left">{this.props.item.author}</span>
                    <span className="float-right">
                        {new Date(this.props.item.created_at.seconds * 1000).toLocaleDateString()}
                    </span>
                </Card.Footer>
            </Card>
        </Col>;
    }
}