import SRItemViewer from "./SRItemViewer";
import { getFirebase } from "../shcontext/SRFirebase";

export default class SRContentViewer extends SRItemViewer {
    async getItemData(id: string): Promise<any> {
        const srFirebase = getFirebase(this.props);
        if (srFirebase) {
            const content = await srFirebase.getAPI(this.props.api)?.fetchContent(id);
            if (content) {
                return content.data();
            }
        }
    }
}