import "@fortawesome/fontawesome-free/css/all.css";
import * as React from "react";
import { BrowserRouter as Router, Route, Switch, withRouter } from "react-router-dom";
import SRHome from "./components/dashboard/SRHome";
import BreakingNewsSelectionList from "./components/editor/BreakingNewsSelectionList";
import ContentEditor from "./components/editor/ContentEditor";
import ContentStagingList from "./components/editor/ContentStagingList";
import ContentListPage from "./components/list/ContentListPage";
import { withContext } from "./components/shcontext";
import SRProfile from "./components/SRProfile";
import SRSignIn from "./components/SRSignIn";
import SRSignUp from "./components/SRSignUp";
import SRVerifyCode from "./components/SRVerifyCode";
import SRContentViewer from "./components/viewer/SRContentViewer";
import SRAbout from "./components/SRAbout";
import SRContactUs from "./components/SRContactUs";
import SRFaq from "./components/SRFaq";

const articleProps = { api: 'articles', routePath: 'blog', contentName: 'Article' }
const newsProps = { api: 'news', routePath: 'news', contentName: 'News' }
const malwareProps = { api: 'malware', routePath: 'malware/news', contentName: 'Malware' }
const malwareStagingProps = { api: 'malware', routePath: 'malware', contentName: 'Malware' }
const nhStagProps = { api: 'neighbourhood', routePath: 'nhalert', contentName: 'Neighbourhood Alert' }
const nhProps = { api: 'neighbourhood', routePath: 'neighbourhood/news', contentName: 'Neighbourhood Alert' }
const cyberProps = { api: 'cybernews', routePath: 'cyberlaw/news', contentName: 'Cyber News' }
const cyberStageProps = { api: 'cybernews', routePath: 'cyberlaw', contentName: 'Cyber News' }


const HomeWithContext = withContext(SRHome);
const SignInWithContext = withRouter(withContext(SRSignIn));
const SignUpWithContext = withRouter(withContext(SRSignUp));
const ProfileWithContext = withRouter(withContext(SRProfile));
const NewsWithContext = withRouter(withContext(ContentListPage, newsProps));
const ArticlesWithContext = withRouter(withContext(ContentListPage, articleProps));
const ArticlesStagingWithContext = withRouter(withContext(ContentStagingList, articleProps));
const ArticlesEditorWithContext = withRouter(withContext(ContentEditor, articleProps));
const MalwareEditorWithContext = withRouter(withContext(ContentEditor, malwareStagingProps));
const MalwareStagingWithContext = withRouter(withContext(ContentStagingList, malwareStagingProps));
const NewsStagingWithContext = withRouter(withContext(ContentStagingList, newsProps));
const BreakingNewsSelectionWithContext = withRouter(withContext(BreakingNewsSelectionList));
const NewsEditorWithContext = withRouter(withContext(ContentEditor, newsProps));
const ArticleViewer = withRouter(withContext(SRContentViewer, articleProps));
const NewsViewer = withRouter(withContext(SRContentViewer, newsProps));
const CyberNewsWithContext = withRouter(withContext(ContentListPage, cyberProps));
const CyberNewsViewer = withRouter(withContext(SRContentViewer, cyberProps));
const NeighbourhoodAlertsWithContext = withRouter(withContext(ContentListPage, nhProps));
const NeighbourhoodAlertViewer = withRouter(withContext(SRContentViewer, nhProps));
const VerifyWithContext = withRouter(withContext(SRVerifyCode));
const MalwareNewsViewer = withRouter(withContext(SRContentViewer, malwareProps));
const MalwareNewsWithContext = withRouter(withContext(ContentListPage, malwareProps));

const NhAlertEditorWithContext = withRouter(withContext(ContentEditor, nhStagProps));
const NhAlertStagingWithContext = withRouter(withContext(ContentStagingList, nhStagProps));
const CyberNewsEditorWithContext = withRouter(withContext(ContentEditor, cyberStageProps));
const CyberNewsStagingWithContext = withRouter(withContext(ContentStagingList, cyberStageProps));

const SRContactUsComp = withRouter(withContext(SRContactUs));

class SRApp extends React.Component {

    render() {
        return (
            <div>
                <Router>
                    <Switch>
                        <Route path="/" exact>
                            <HomeWithContext />
                        </Route>
                        <Route path="/home" exact>
                            <SRHome />
                        </Route>
                        <Route path="/signin" exact>
                            <SignInWithContext />
                        </Route>
                        <Route path="/signup" exact>
                            <SignUpWithContext />
                        </Route>
                        <Route path="/news" exact>
                            <NewsWithContext title="News" />
                        </Route>
                        <Route path="/news/:id" exact>
                            <NewsViewer />
                        </Route>
                        <Route path="/blog" exact>
                            <ArticlesWithContext title="Blog" />
                        </Route>
                        <Route path="/blog/:id" exact>
                            <ArticleViewer />
                        </Route>
                        <Route path="/malware/news" exact>
                            <MalwareNewsWithContext title="Malware News" />
                        </Route>
                        <Route path="/malware/news/:id" exact>
                            <MalwareNewsViewer />
                        </Route>
                        <Route path="/staging/blog" exact>
                            <ArticlesStagingWithContext title="My Articles"
                                noItemsMessage="No articles yet. Create your first" />
                        </Route>
                        <Route path="/staging/blog/new">
                            <ArticlesEditorWithContext title="New Article"></ArticlesEditorWithContext>
                        </Route>
                        <Route path="/staging/blog/edit/:id">
                            <ArticlesEditorWithContext title="Edit Article"></ArticlesEditorWithContext>
                        </Route>

                        <Route path="/staging/malware" exact>
                            <MalwareStagingWithContext title="My Malware Posts"
                                noItemsMessage="No posts yet. Create your first" />
                        </Route>
                        <Route path="/staging/malware/new">
                            <MalwareEditorWithContext title="New Malware Post"></MalwareEditorWithContext>
                        </Route>
                        <Route path="/staging/malware/edit/:id">
                            <MalwareEditorWithContext title="Edit Malware Post"></MalwareEditorWithContext>
                        </Route>

                        <Route path="/staging/news" exact>
                            <NewsStagingWithContext title="My News Items"
                                noItemsMessage="No news items yet. Create your first" />
                        </Route>
                        <Route path="/staging/news/new">
                            <NewsEditorWithContext title="New News Item"></NewsEditorWithContext>
                        </Route>
                        <Route path="/staging/news/edit/:id">
                            <NewsEditorWithContext title="Edit News Item"></NewsEditorWithContext>
                        </Route>

                        <Route path="/cyberlaw/news" exact>
                            <CyberNewsWithContext title="Cyber Law News" />
                        </Route>
                        <Route path="/cyberlaw/news/:id" exact>
                            <CyberNewsViewer />
                        </Route>

                        <Route path="/staging/cyberlaw/new">
                            <CyberNewsEditorWithContext title="New Cyber Law News"></CyberNewsEditorWithContext>
                        </Route>
                        <Route path="/staging/cyberlaw/edit/:id">
                            <CyberNewsEditorWithContext title="Edit Cyber Law News"></CyberNewsEditorWithContext>
                        </Route>
                        <Route path="/staging/cyberlaw" exact>
                            <CyberNewsStagingWithContext title="My Cyber Law News"
                                noItemsMessage="No news items yet. Create your first" />
                        </Route>


                        <Route path="/profile" exact>
                            <ProfileWithContext />
                        </Route>
                        <Route path="/neighbourhood/news" exact>
                            <NeighbourhoodAlertsWithContext title="Neighbourhood Alerts" category_title="Neighbourhoods" />
                        </Route>
                        <Route path="/neighbourhood/news/:id" exact>
                            <NeighbourhoodAlertViewer />
                        </Route>
                        <Route path="/admin/news/breaking" exact>
                            <BreakingNewsSelectionWithContext title="Admin - Breaking News" />
                        </Route>

                        <Route path="/__/auth/action" exact>
                            <VerifyWithContext />
                        </Route>
                        <Route path="/staging/nhalert/new">
                            <NhAlertEditorWithContext title="New Neighbourhood Alert"></NhAlertEditorWithContext>
                        </Route>
                        <Route path="/staging/nhalert/edit/:id">
                            <NhAlertEditorWithContext title="Edit Neighbourhood Alert"></NhAlertEditorWithContext>
                        </Route>
                        <Route path="/staging/nhalert" exact>
                            <NhAlertStagingWithContext title="My Neighbourhood Alerts"
                                noItemsMessage="No news items yet. Create your first" />
                        </Route>
                        <Route path="/aboutus" exact>
                            <SRAbout/>
                        </Route>
                        <Route path="/contactus" exact>
                            <SRContactUsComp/>
                        </Route>
                        <Route path="/faq" exact>
                            <SRFaq/>
                        </Route>
                    </Switch>
                </Router>
            </div>
        );
    }

    /*componentDidMount() {
        document.body.onscroll = (event: any) => {
            const element = document.body;
            console.info(`ScrollHeight - ${element.scrollHeight},
                                   ScrollTop - ${element.scrollTop},
                                   ClientHeight - ${element.clientHeight}`);
            if(element.scrollTop !== 0) {
                if (element.scrollHeight - element.scrollTop === element.clientHeight) {
                    SREventBus.dispatch('sr_view_scroll_end', event);
                }
            }
        }
    }*/

}

export default SRApp;
